.dialogParentDiv {
  @apply pt-[50px]
}

.dialogTitle {
  @apply w-full font-semibold text-center text-[#2D3748] text-[24px] px-[16px] px-[24px]
}

.dialogContentContainer {
  @apply pb-[40px]
}

.dialogContent {
  @apply w-full font-normal text-center text-[#2D3748] text-[18px] px-[16px] px-[24px]
}

.buttonContainer {
  @apply w-full flex justify-center text-center pb-[35px] gap-3
}

.btnConfirm {
  @apply w-[200px] p-[15px] border-0 cursor-pointer bg-[#03EDA5] text-[16px] text-[#040404] font-semibold rounded-md  items-center; 
}

.btnCancel {
  @apply w-[200px] p-[15px] border-0 cursor-pointer bg-[#DBDBDB] text-[16px] text-[#040404] font-semibold rounded-md items-center;
}